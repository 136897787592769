import "./about.css";
import Me2 from "../../img/me2.jpg"


const About = () => {
  return (
    <div className="a">
      <div className="a-left">
        <div className="a-card bg"></div>
        <div className="a-card">
          <img
            src={Me2}
            alt=""
            className="a-img"
          />
        </div>
      </div>
      <div className="a-right">
        <h1 className="a-title">Tentang Saya</h1>
        <p className="a-sub">
          Saya Pranata Komputer yang ditugaskan di BPS Kota Gunungsitoli
        </p>
        <p className="a-desc">
          Saya lulusan baru DIV-Komputasi Statistik di Politeknik Statistika STIS.
          Saya senang berkarya untuk membangun web sederhana untuk membantu pekerjaan sehari-hari.
          Saat ini, saya sedang mencari pengalaman untuk mengembangkan skill saya lebih baik lagi.
          Yang mana saya cukup bisa dalam menggunakan Canva, Figma, Html, Css, Php, dan Javascript.
        </p> <br></br>
        <p className="a-desc">
          Ketika saya tidak bekerja, Anda bisa menemukan saya sedang istirahat, bermain game, futsal atau memandangi pantai.
        </p>
      </div>
    </div>
  )
}

export default About
