import { useContext } from "react";
import Intro from "./komponen/intro/Intro";
import About from "./komponen/about/About";
import ProductList from "./komponen/productList/ProductList";
import Contact from "./komponen/contact/Contact";
import Toggle from "./komponen/toggle/Toggle";
import { ThemeContext } from "./context";

const App = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div style={{backgroundColor:darkMode ? "#222" : "white", color: darkMode && "white", }}>
      <Toggle />
      <Intro />
      <About />
      <ProductList />
      <Contact />
    </div>
  );
};

export default App;
