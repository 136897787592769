import "./contact.css";
import Phone from "../../img/whatsapp.png";
import Email from "../../img/email.png";
import Address from "../../img/location.png";
import { useRef, useState, useContext } from "react";
import emailjs from "@emailjs/browser";
import { ThemeContext } from "../../context";

const Contact = () => {
    const formRef = useRef();
    const [done, setDone] = useState(false);
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    const handleSubmit = (e) => {
      e.preventDefault();
      emailjs.sendForm('service_lyyrjtl', 'template_7lrhxkv', formRef.current, 'mt4FlwoA2swSkmtf5')
      .then((result) => {
          console.log(result.text);
          setDone(true);
      }, (error) => {
          console.log(error.text);
      });
    };
  return (
    <div className="c">
      <div className="c-bg"></div>
      <div className="c-wrapper">
        <div className="c-left">
          <h1 className="c-title">Hubungi Saya, Jika Membutuhkan</h1>
          <div className="c-info">
            <div className="c-info-item">
              <img
                src={Phone}
                alt=""
                className="c-icon"
              />
              +62 821 6740 2640
            </div>
            <div className="c-info-item">
              <img
                src={Email}
                alt=""
                className="c-icon"
              />
              banuburkhairi16@gmail.com
            </div>
            <div className="c-info-item">
              <img
                src={Address}
                alt=""
                className="c-icon"
              />
              Medan Marelan, Kota Medan, Sumatera Utara, Indonesia 20255
            </div>
          </div>
        </div>
        <div className="c-right">
          <p className="c-desc">
            <b>Apa yang kamu butuhkan</b> Kirim pesan ke saya mari kita ngobrol dengan kebutuhan anda. Boleh itu projek atau hal lainnya.
          </p>
          <form ref={formRef} onSubmit={handleSubmit}>
            <input style={{backgroundColor: darkMode && "#333"}} type="text" placeholder="Nama" name="user_name" />
            <input style={{backgroundColor: darkMode && "#333"}} type="text" placeholder="Subjek" name="user_subjek" />
            <input style={{backgroundColor: darkMode && "#333"}} type="text" placeholder="Email" name="user_email" />
            <textarea style={{backgroundColor: darkMode && "#333"}} rows="5" placeholder="Pesan" name="message"/>
            <button>Kirim</button>
            {done && "Terima Kasih"}
          </form>
        </div>
      </div>
    </div>
  )
}

export default Contact
