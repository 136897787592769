export const products = [
  {
    id: 1,
    img: "https://banu.my.id/img/projek_myweb.png",
    link: "https://banu.my.id",
  },
  {
    id: 2,
    img: "https://banu.my.id/img/projek_lapjj.jpg",
    link: "https://banu.my.id/Laporan_Mama",
  },
  {
    id: 3,
    img: "https://banu.my.id/img/projek_desainsipadu.png",
    link: "https://banu.my.id/sipadubanu",
  },
  {
    id: 4,
    img: "https://banu.my.id/img/projek_ip.png",
    link: "https://banu.my.id",
  },
  {
    id: 5,
    img: "https://banu.my.id/img/projek_sistama.jpg",
    link: "https://bapusta1275.web.id/sistama",
  },
  {
    id: 6,
    img: "https://banu.my.id/img/projek_rapot.jpg",
    link: "https://play.google.com/store/apps/details?id=ghozien.indikator.bps.prov",
  }
]
