import "./productList.css";
import Product from "../product/Product"
import {products} from "../../data"

const ProductList = () => {
  return (
    <div className="pl">
      <div className="pl-texts">
        <h1 className="pl-title">Projek yang Pernah Saya Kerjakan</h1>
        <p className="pl-desc">
          Projek-projek ini ada yang saya bangun dari awal ada juga yang saya kembangkan ulang bersama teman kerja saya. Projek yang saya kerjakan biasanya diperuntukkan untuk membantu Ibu saya sebagai guru, pekerjaan tempat saya bekerja, dan lainnya untuk menambah pengalaman saya.
        </p>
      </div>
      <div className="pl-list">
        {products.map((item) => (
          <Product key={item.id} img={item.img} link={item.link}/>
        ))}
      </div>
    </div>
  )
}

export default ProductList
